<script lang="ts">
  import clsx from "clsx";
  import { AlertCircle, CheckCircle2, Loader2, XCircle } from "lucide-svelte";

  // Color / icon state
  export let type: string;
  // Text to display in the pill
  export let text: string;

  $: bgColor = {
    failed: "bg-red-400/10",
    timeout: "bg-red-400/10",
    cancelled: "bg-red-400/10",
    succeeded: "bg-primary/[15%]",
    pending: "bg-zinc-400/[15%]",
    running: "bg-accent-pink/[15%]",
    httperror: "bg-yellow-500/10",
  }[type];

  $: textColor = {
    failed: "text-red-400",
    timeout: "text-red-400",
    cancelled: "text-red-400",
    succeeded: "text-primary",
    pending: "text-zinc-400",
    running: "text-accent-pink/90",
    httperror: "text-yellow-500",
  }[type];
</script>

<div
  class={clsx(
    "flex whitespace-nowrap items-center rounded-full gap-x-1.5 px-2 py-0.5 -mx-2 -my-0.5",
    bgColor,
    textColor,
  )}
>
  {#if type === "succeeded"}
    <CheckCircle2 size={14} />
  {:else if type === "running" || type === "pending"}
    <Loader2 class="animate-spin" size={16} />
  {:else if type === "httperror"}
    <AlertCircle size={16} />
  {:else}
    <XCircle size={16} />
  {/if}
  <span class="font-medium">
    {text}
  </span>
</div>
